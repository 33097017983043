html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
}

.border {
  border: thin solid red;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.fill {
  min-height: 100%;
}

.widthFill {
  min-width: 100%;
}

.pt-1 {
  padding-top: 1em;
}

.pb-1 {
  padding-bottom: 1em;
}

.pl-1 {
  padding-left: 1em;
}

.pl-2 {
  padding-left: 2em;
}

.pr-1 {
  padding-right: 1em;
}

.pt-2 {
  padding-top: 2em;
}

.pt-3 {
  padding-top: 3em;
}

.pt-6 {
  padding-top: 6em;
}

.pt-7 {
  padding-top: 7em;
}

.pb-2 {
  padding-bottom: 2em;
}

.pb-4 {
  padding-bottom: 4em;
}

.mt-050 {
  margin-top: 0.5em !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mt-5 {
  margin-top: 5em !important;
}

.mt-6 {
  margin-top: 6em !important;
}

.mt-7 {
  margin-top: 7em !important;
}

.mt-8 {
  margin-top: 8em !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 1em !important;
}

.m-2 {
  margin: 2em !important;
}

.p-10 {
  padding: 1em !important;
}

.mr-025 {
  margin-right: 0.25em !important;
}

.mr-050 {
  margin-right: 0.5em !important;
}

.mr-075 {
  margin-right: 0.75em !important;
}

.mr-1 {
  margin-right: 1em !important;
}

.ml-1 {
  margin-left: 1em !important;
}

.ml-2 {
  margin-left: 2em !important;
}

.mr-2 {
  margin-right: 2em !important;
}

.mr-3 {
  margin-right: 3em !important;
}

.ml-3 {
  margin-left: 3em !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mt-2 {
  margin-top: 2em !important;
}

.mt-3 {
  margin-top: 3em !important;
}

.mt-4 {
  margin-top: 4em !important;
}

.mb-4 {
  margin-bottom: 4em !important;
}

.mb-3 {
  margin-bottom: 3em !important;
}

.mb-2 {
  margin-bottom: 2em !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.p-0 {
  margin: 0 !important;
}

.height-2 {
  height: 2em !important;
}

.height-3 {
  height: 3em !important;
}

.height-4 {
  height: 4em !important;
}

.common-shadow {
  box-shadow: 1px 0px 10px rgba(47, 47, 47, 0.2);
}

app-simple-steps {
  text-align: center;
}

.borderRClass {
  border-radius: 10px !important;
}

.bold {
  font-weight: bold;
  color: white;
}

.borderInput {
  border-color: #2b959e !important;
  box-sizing: border-box !important;
  border-radius: 4.83244px !important;
}

.marginRow {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.redColor {
  color: red;
}

.section-header {
  font-size: 36px;
  color: #303030;
  font-weight: 800;
}

.content-header {
  font-size: 36px;
  font-weight: 700;
}

.main-title {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}

.sub-title {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.main-sec-btn {
  background: #fff;
  color: #2BB2E4;
  font-weight: 700;
  padding: 8px 35px;
  border-radius: 7px !important;
}

.main-inputs {
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 11px;
  color: #fff;
  text-align: left !important;
  padding: 15px auto !important;
}

@media screen and (min-width: 1024px) {
  .section-header {
    font-size: 38px;
    font-weight: 800;
  }
  .content-header {
    font-size: 38px;
    font-weight: 700;
  }
  .main-title {
    font-size: 55px;
  }
  .sub-title {
    font-size: 18px;
  }
}