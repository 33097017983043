// overrides default ant design theme

// font
// @font-face {
//   font-family: "ProductSans Regular";
//   src: url("@app/../assets/ProductSans-Regular.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "arial";
//   src: url("@app/../assets/arial.ttf") format("truetype");
// }

// @font-face {
//   font-family: "ProductSans Light";
//   src: url("@app/../assets/ProductSans-Light.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }
@import "../../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// @font-face {
//   font-family: "apercu";
//   src: url("@app/../assets/fonts/apercu.otf") format("opentype");
// }

@font-family: 'Nunito Sans', sans-serif;
// html { font-size: calc(1em + 1vw); }

// color
// primary color for all components
// @primary-color: #27878e;
// @primary-color: #f05f24;
@primary-color: #261C15;
@footer: #292f36;
//bodybackground
@component-background: #fff;

@layout-body-background: #f9ffff;
@body-background: #fff;
@layout-footer-background: #2f2f2f;
@layout-footer-padding: 5px 50px;
// buttons
@btn-primary-bg: @primary-color;

// link color
@link-color: @primary-color;

// processing color
@processing-color: #fed605;

// info state color
@info-color: @primary-color;

// success state color
@success-color: #8dda51;

// warning state colorÏÏÏÏ
@warning-color: #fed605;

// error state color
@error-color: #cc343e;
// @error-color: #f8f8f8;

//btnWhite
@btnWhite-color: #fff;
// heading text color
@heading-color: #000;

// major text color
@text-color: #292929;

// secondary text color
@text-color-secondary: rgba(0, 0, 0, 0.45);

// disable state color
@disabled-color: rgba(0, 0, 0, 0.25);

// major border color
@border-color-base: #d9d9d9;

// size
// major text font size
@font-size-base: 16px;

// major border radius
@border-radius-base: 4px;

//menu bg
@menu-item-active-bg: white;
@menu-dark-item-hover-bg: red;

@menu-collapsed-width: 80px;
@menu-bg: "#2b959"; //@component-background;
// @menu-popup-bg: @component-background;
@menu-item-color: white;
@menu-inline-submenu-bg: "#2b959";
@menu-inline-submenu-color: white;

// @menu-inline-submenu-bg:'#2b959';
// @menu-highlight-color: @secondary-color;
// @menu-highlight-danger-color: @error-color;
// @menu-item-active-bg: transparent; //@primary-1;
// @menu-item-active-danger-bg: @red-1;
// @menu-item-active-border-width: 0px;
// @menu-item-group-title-color: @text-color-secondary;
// @menu-item-vertical-margin: 4px;
// @menu-item-font-size: @font-size-base;
// @menu-item-boundary-margin: 8px;
// @menu-item-padding: 0 20px;
// @menu-horizontal-line-height: 46px;
// @menu-icon-margin-right: 10px;
// @menu-icon-size: @menu-item-font-size;
// @menu-icon-size-lg: @font-size-lg;
// @menu-item-group-title-font-size: @menu-item-font-size;

.owl-theme .owl-nav [class*=owl-] {
    background: transparent !important;
}

.owl-prev {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.owl-next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}